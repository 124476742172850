/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import DashIcon from '../icons/Dash';

const HighlightSection = () => {
  const data = useStaticQuery(graphql`
  {
    contentfulHighlights {
      heading
      highlightCards {
        name
        title
        slug
        releaseDate
        duration
        description
        video {
          url
          vidoePlaceholder {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`);

  const { contentfulHighlights } = data || {};
  const firstPostLength = contentfulHighlights.highlightCards.length - 1;
  const firstPost = contentfulHighlights.highlightCards[firstPostLength - 2];
  const secondPost = contentfulHighlights.highlightCards[firstPostLength - 1];
  const thirdPost = contentfulHighlights.highlightCards[firstPostLength];
  return (
    <section className="snap-start bg-background">
      <div className="pb-[4px] pt-[20px] lg:py-[40px]">
        <div className="md:px-[2rem] lg:px-[3rem] px-[1.2rem]">
          <div className="mb-[40px]">
            <div className="mb-[20px] block">
              <h1 className="text-heading font-normal md:text-headerH1Md text-headerH1 lg:text-headerH1 relative mb-[2px]">
                {data.contentfulHighlights.heading}
              </h1>
              <DashIcon />
            </div>
            <div className="grid grid-flow-cols-1 grid-flow-row md:grid-cols-3 lg:grid-cols-4 md:grid-flow-auto max-w-full w-full gap-5">
              <article className="max-w-full w-full lg:col-span-2 col-span-1 group duration-200 delay-75 hover:z-30 z-20">
                <div className="h-full relative hover-img overflow-hidden z-30 transition transform duration-500 md:group-hover:scale-110 lg:group-hover:scale-[1.07]  group-hover:scale-105">
                  <Link to={`/highlights/${firstPost.slug}`} alt={firstPost.title} className="after:group-hover:border-primary after:group-hover:bottom-[-3px] after:h-0 after:w-full after:absolute after:rounder-full after:group-hover:border-b-[0.25rem] after:left-0 after:z-10">
                    <GatsbyImage image={firstPost.video.vidoePlaceholder.gatsbyImageData} alt={firstPost.title} className="max-w-full w-full mx-auto h-[200px] md:h-full object-contain object-center shadow-md z-0 group-hover:shadow-lg" />
                  </Link>
                  <div className="absolute px-4 pt-7 pb-4 bottom-0 w-full">
                    <div className="pt-1 flex items-center justify-between">
                      <div className="text-gray-100">
                        <div className="inline-block h-3 border-l-2 border-primary mr-2" />
                        {firstPost.title}
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <article className="max-w-full w-full group duration-200 delay-75 hover:z-30 z-20">
                <div className="h-full relative hover-img overflow-hidden z-30 transition transform duration-500 md:group-hover:scale-110 lg:group-hover:scale-[1.07]  group-hover:scale-105">
                  <Link to={`/highlights/${secondPost.slug}`} alt={secondPost.title} className="after:group-hover:border-primary after:group-hover:bottom-[-3px] after:h-0 after:w-full after:absolute after:rounder-full after:group-hover:border-b-[0.25rem] after:left-0 after:z-10">
                    <GatsbyImage imgStyle={{ objectPosition: 'center' }} image={secondPost.video.vidoePlaceholder.gatsbyImageData} alt={secondPost.title} className="max-w-full w-full mx-auto h-[200px] object-center object-contain md:h-full shadow-md z-0 group-hover:shadow-lg" />
                  </Link>
                  <div className="absolute px-4 pt-7 pb-4 bottom-0 w-full">
                    <div className="pt-1 flex items-center justify-between">
                      <div className="text-gray-100">
                        <div className="inline-block h-3 border-l-2 border-primary mr-2" />
                        {secondPost.title}
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <article className="max-w-full w-full group duration-200 delay-75 hover:z-30 z-20">
                <div className="h-full relative hover-img overflow-hidden z-30 transition transform duration-500 md:group-hover:scale-110 lg:group-hover:scale-[1.07]  group-hover:scale-105">
                  <Link to={`/highlights/${thirdPost.slug}`} alt={thirdPost.title} className="after:group-hover:border-primary after:group-hover:bottom-[-3px] after:h-0 after:w-full after:absolute after:rounder-full after:group-hover:border-b-[0.25rem] after:left-0 after:z-10">
                    <GatsbyImage image={thirdPost.video.vidoePlaceholder.gatsbyImageData} alt={thirdPost.title} className="max-w-full w-full mx-auto h-[200px] md:h-full object-center object-contain shadow-md z-0 group-hover:shadow-lg" />
                  </Link>
                  <div className="absolute px-4 pt-7 pb-4 bottom-0 bg-gradient-cover w-full">
                    <div className="pt-1 flex items-center justify-between">
                      <div className="text-gray-100">
                        <div className="inline-block h-3 border-l-2 border-primary mr-2" />
                        {thirdPost.title}
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <hr className="border-0 w-[90%] h-[1px] opacity-50  bg-gradient-to-r from-transparent via-border-color to-transparent" />
        </div>
      </div>
    </section>
  );
};

export default HighlightSection;
